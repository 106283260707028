@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?mjihwt');
  src:  url('fonts/icomoon.eot?mjihwt#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?mjihwt') format('truetype'),
    url('fonts/icomoon.woff?mjihwt') format('woff'),
    url('fonts/icomoon.svg?mjihwt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelop:before {
  content: "\e945";
}

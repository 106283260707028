/* Estilo general de la tienda */


.containerTienda {
    background-color: #343a40  ;
    min-height: 100vh;
}
.rowTienda{
    width: 65%;
    margin-left: 10%;
}

h2 {
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Estilo para las tarjetas de los coches */
.tienda-card {
    background-color: black;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.tienda-card:hover {
    transform: translateY(-10px);
}

.tienda-card-img {
    height: 250px;
    object-fit: cover;
}



/* Estilos para el texto en las tarjetas */
.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}


.fw-bold {
    font-size: 1rem;
    color: #ffffff;
}

.text {
    font-size: 1.2rem;
}

/* Botón de reserva */
.btn{
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.buttom {
    margin-top: 1.5rem;
}
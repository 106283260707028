/* home.css */
.home {
    background: linear-gradient(to bottom, #2c2c2c, #2b6c8e); 
    background-size: cover;
    background-position: center;
    min-height: 100vh;

}
.logo{
    border-radius: 5px;
}

.cabecera {
    background-color: #2b6c8e;
    color: #ffffff;
}
#navbarNav{

    margin-left: 10%;
}


.acerca-de-section, .seccion-coches, .consulta-section {
    background-color: #2C2C2C; 
    color: #ffffff; 
    border: 1px solid #2b6c8e; 
    border-radius: 8px;
    padding: 20px;
}


#boton{
    background-color:#2b6c8e;
}



form .form-label {
    color: #ffffff;
}

form .form-control {
  
    border-color: #2b6c8e; 
}

form .form-control::placeholder {
    color: #aaaaaa; 
}

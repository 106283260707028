/* Estilo general del contenedor */
.tabla{
    background-color: #1e3a5f;
    min-height: 100vh;
}

input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 8px;
    width: 100%;
    transition: border-color 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Efecto de foco en el input */
input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
}

/* Botón de búsqueda */
button[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

button[type="submit"]:hover {
    background-color: #0056b3;
    transform: scale(1.02);
}

/* Botón para mostrar todos */
button.mt-3 {
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

button.mt-3:hover {
    transform: scale(1.02);
}

/* Estilo de la tabla */
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    color: black;
    background-color: white;
}

th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}


th {

    font-weight: bold;

}



.cookie-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.cookie-popup-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 100%;
}

.cookie-popup-content h2 {
    margin-bottom: 1rem;
}

.cookie-popup-content button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #00aaff;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.cookie-popup-content button:hover {
    background-color: #007acc;
}

/* Documentacion.css */
.containerCompra {
    background: linear-gradient(to bottom, #2c2c2c, #2b6c8e);
    min-height: 100vh;  /* Asegura que ocupa toda la altura de la vista */
    min-width: 100vw;  /* Ajusta para que ocupe todo el ancho del viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.registro-container2 {
    display: flex;
    flex-direction: column; /* Column layout for all screens initially */
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
    .registro-container2 {
        flex-direction: row; /* Row layout for large screens and above */
    }
}

.registro-left2, .registro-right2 {
    width: 100%;
}

@media (min-width: 992px) {
    .registro-left2 {
        width: 35%; /* Adjusted width for large screens */
    }
    .registro-right2 {
        width: 40%; /* Adjusted width for large screens */
    }
}

input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="number"], select {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    border-bottom: 1px solid white;
    width: 100%;
    padding: 8px;
}

input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

button {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    background-color: #2b6c8e;
    border: none;
}

button:hover {
    background-color: #245b78;
}

label {
    color: #ffffff;
}

.text-danger {
    font-size: 0.9rem;
}
